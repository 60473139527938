export default {
  // ? =========================
  // ? =======  Layout  ========
  // ? =========================

  navigation: {
    '.container': {
      // backgroundColor: 'white',
      // position: 'static',
      // borderBottom: '1px solid lightgrey',
      padding: ['0.5rem', '0.5rem', '0rem 1rem', '0rem 1rem'],
      zIndex: '1003'
    },
    '.hamburger div': {
      color: 'white'
    },
    '.containerScrolled': {
      backgroundColor: 'dark',
      borderBottom: '1px solid lightgrey',
      zIndex: '1003'
    },
    '.smallNavMenu > div': {
      borderColor: 'light',
      '.navItem': {
        color: 'light',
        a: {
          fontSize: ['1.25rem']
        }
      }
    },

    '.hamburger > div': {
      backgroundColor: 'white'
    },

    '.navMenuLogo': {
      position: 'static',
      width: '100%'
    },

    '.navMenuOpen': {
      backgroundColor: 'dark',
      width: ['90%', '70%', '30%', '30%'],
      color: 'light',
      '.seperatorLine': {
        display: 'none'
      },
      '.navItem': {
        width: '100%',
        borderBottom: 'solid 1px white',
        padding: '0.5rem',
        '::before': {
          content: "'⬢'",
          margin: '0rem 0.5rem'
        },
        '::after': {
          content: "'⬢'",
          margin: '0rem 0.5rem'
        }
      }
    },
    '.phoneSocialContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    '.socialIconsContainer, .phoneContainer': {
      color: 'primary',
      borderColor: 'primary',
      width: '48%',
      svg: {
        path: {
          fill: 'primary'
        }
      }
    },
    '.logoScrolled': {
      img: {
        maxHeight: ['65px', '65px']
      }
    },

    '.navBlockOpen': {
      width: ['100%', '100%', '100%', '100%'],
      height: '100vh',
      backgroundColor: '#ffffffc9',
      top: '0rem',
      position: 'fixed',
      left: 'unset',
      right: '0%'
    }
  },
  footer: {
    padding: ['1rem 0.5rem 6rem', '1rem 2rem 6rem ', '1rem 2rem', '1rem 2rem'],
    backgroundColor: 'black',
    display: 'flex',
    flexWrap: 'wrap',
    color: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(1)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white',
      margin: '1rem 1rem'
    },
    '.multiButtonContainer, .image, .socialContainer  ': {
      display: 'none'
    },

    '.contactDetails-container': {
      display: 'flex',
      color: 'white',
      flexDirection: 'row',
      alignItems: 'center',
      '.text': {
        margin: '0rem 1rem',
        fontSize: '0.8rem',
        display: 'flex',
        color: 'white',
        flexDirection: 'row',
        alignItems: 'center'
      }
    }
  },

  ctaWidget: {
    a: {
      backgroundColor: 'rgb(208,156,0)',
      borderRadius: ['', '', '300px']
    }
  },

  // ? =========================
  // ? ======  reuseables  ======
  // ? =========================

  title: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '2.5rem', '3rem', '4rem'],
    color: 'primary',
    fontWeight: '500',
    whiteSpace: 'pre-wrap',
    order: '1',
    textAlign: 'left'
  },
  subtitle: {
    color: 'text',
    fontWeight: 'bold',
    textTransform: 'initial',
    fontFamily: 'body',
    fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase',
    order: '2',
    marginBottom: '2rem',
    color: 'inherit',
    textAlign: 'left'
  },
  text: {
    lineHeight: '1.75',
    marginBottom: '1rem',
    order: '3',
    p: {
      color: 'inherit',
      textAlign: 'left'
    }
  },

  sideBySide1: {
    padding: '0rem',
    height: ['', '', '80vh', '80vh'],
    backgroundPosition: ['center 100%', '', 'center center'],
    backgroundSize: ['150%', '', 'cover'],
    '.section': {
      width: ['', '', '40%'],
      maxWidth: 'unset',
      margin: ' 0rem auto 0rem 0rem',
      backgroundColor: 'white',
      padding: ['2rem 1rem', '', '3rem'],
      marginBottom: ['100%', '', '0rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.section': {
      width: ['', '', '40%'],
      maxWidth: 'unset',
      margin: ' 0rem 0rem 0rem auto',
      backgroundColor: 'black',
      padding: ['1rem', '', '3rem'],
      color: 'white',
      padding: ['2rem 1rem', '', '3rem'],
      marginBottom: ['100%', '', '0rem'],
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? =========================
  // ? ======  Home page  ======
  // ? =========================

  homepageHero: {
    height: ['100vh', '', '80vh'],
    // marginBottom: ['2rem', '', '2rem', '2rem'],
    '::after': {
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0rem',
      top: '0rem',
      background: 'linear-gradient(193deg, rgba(46, 59, 73, 0) 0%, rgba(49, 55, 62, 0) 64%, rgb(0, 0, 0) 100%)'
    },
    '.slick-slider': {
      height: ['100vh', '', '80vh'],
      '.slick-slide > div': {
        height: ['100vh', '', '80vh']
      },
      '.slick-prev, .slick-next': {
        display: 'none !important',
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px'
      },
      '.slick-slide img': {
        height: ['100vh', '', '80vh']
      }
    },
    '.hero_content_container': {
      // padding: ['2rem', '', '', '4.5rem', '5.5rem'],
      textShadow: '3px 1px 1px black',
      width: ['100%', '', '75%'],
      // marginLeft: ['', '', '', '4rem'],
      top: 'unset',
      margin: '0rem',
      bottom: '0rem',
      transform: 'unset',
      // display: 'none',
      '.title': {
        variant: 'customVariants.title',
        '::after': {} // removes the star
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontSize: ['2rem', '', '3rem', '3rem'],
        borderBottom: 'solid 4px',
        color: 'white',
        width: 'fit-content'
      },

      '.text': {
        variant: 'customVariants.text',
        color: 'light',
        marginBottom: 'unset',
        fontSize: ['1rem', '1.25rem']
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    backgroundColor: 'background',
    flexWrap: ['', '', 'nowrap'],
    height: ['', '', '20vh'],
    '.title': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      backgroundColor: ['primary', '', '', 'primary']
    },
    '.text': {
      fontSize: ['1.6rem', '', '2rem'],
      order: ['4', '', '3'],
      border: ['', '', 'solid 9px'],
      borderColor: ['', '', 'black'],
      fontFamily: 'heading',
      fontWeight: 'normal',
      padding: ['', '', '0.5rem 1rem'],
      backgroundColor: ['black', '', '', 'primary'],
      color: 'light'
    },
    '.date': {
      backgroundColor: 'secondary',
      color: 'light',
      border: ['', '', '', '#ffffff solid 9px'],
      borderColor: ['', '', '', 'black'],
      fontSize: '0.8rem',
      writingMode: ['', '', 'vertical-rl']
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'black'],
      order: ['', '', '4']
    },
    borderTop: '2px solid black',
    borderBottom: '2px solid black',
    margin: '0rem 0rem'
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },
  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },
  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  // ? =========================
  // ? =====  About page  ======
  // ? =========================

  aboutSection: {
    variant: 'customVariants.sideBySide2'
  },

  // ? =========================
  // ? ====  Meal prep page  ===
  // ? =========================

  menu: {
    '.dropdownContainer': {
      display: 'none'
    },

    marginY: '2rem',

    '.allInContainerWrapper > .allInContainer > .menuContainer  >.menuSectionTitle': {
      fontSize: ['2rem', '', '3rem']
    },
    '*': {
      boxShadow: 'none'
    },
    '.menuLogo': {
      marginBottom: '2rem'
    },
    '.menuSectionTitle': {
      color: 'primary',
      fontWeight: '300',
      borderBottom: 'solid 2px',
      borderColor: 'primary',
      paddingBottom: '0.5rem',
      textAlign: 'left',
      textTransform: 'uppercase',
      width: '95%',
      margin: ['0rem 0rem 1rem 0.5rem', '', '0rem 1.5rem 1.5rem']
    },
    '.menuSectionDescription': {
      marginBottom: '2rem'
    },
    '.menuItemContainer': {
      margin: '0rem'
    },
    '.menuItemName': {
      color: 'black',
      textTransform: 'uppercase',
      // fontWeight: 'bold',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      margin: '0rem',
      fontFamily: 'heading'
    },
    '.menuItemContentContainer': {
      padding: '0rem',
      margin: '0rem'
    },
    '.menuItemPrice': {
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: ['1rem', '1.15rem', '1.25rem'],
      fontFamily: 'heading'
    },
    '.menuItemDescription': {
      fontSize: '1rem',
      fontWeight: '600',
      opacity: '0.6'
    },
    '.section-cell': {
      width: ['100%', 'calc(50% - (0.5rem * 2))', 'calc(50% - (0.5rem * 2))'],
      borderRadius: '0px',
      backgroundColor: 'transparent',
      border: 'none',
      position: 'relative',
      height: '300px',
      '.menuCell': {
        '.cellImageContainer': {
          height: '100%',
          position: 'relative',
          '.imageFill': {
            paddingBottom: 0
          }
        },
        '::after': {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          background: 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 100%)',
          transition: 'background 0.5s ease-in-out'
        },
        ':hover::after': {
          display: 'none',
          background: 'linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 100%)'
        }
      }
    },
    '.menuCell': {
      borderRadius: '0px'
    },

    '.cellImage': {
      borderRadius: '0px'
    },

    '.cellName': {
      position: 'absolute',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      width: '100%',
      top: '50%',
      left: '50%',
      color: 'light',
      textTransform: 'uppercase',
      fontSize: ['2rem', '2.15rem', '2.75rem'],
      margin: '0rem',
      fontFamily: 'heading',
      padding: '0.5rem',
      zIndex: '10'
    }
  },

  // ? =========================
  // ? ====  Gallery page  =====
  // ? =========================

  gallery: {
    padding: '1rem',
    minHeight: '60vh',
    '.albumsContainer': {},
    '.albumsCTA': {
      height: ['350px', '', '500px'],
      alignItems: 'flex-end',
      flexGrow: '1',
      transition: ' all ease-in-out 0.7s',
      ':hover': {
        backgroundPosition: 'bottom right'
      }
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      color: 'white',
      borderColor: 'white',
      fontSize: '2rem',
      marginBottom: '1rem'
    }
  },

  // ? =========================
  // ? ====  events page  =====
  // ? =========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.modal, .modalContentContainer ': {
      backgroundColor: 'white'
    }
  },

  // ? =========================
  // ? ====  Contact Page  =====
  // ? =========================

  locationMap: {
    order: '3',
    color: 'light',

    h3: {
      textTransform: 'uppercase',
      color: 'light'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    },
    '.content_container ': {
      backgroundColor: 'primary'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light',
      order: 'unset'
    }
  },

  contactForm: {
    padding: '4rem 1rem',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
      }
    }
  }
}
