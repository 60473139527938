export default {
  pageHeroV1: {
    container: {
      height: '80vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'flex-end',
      justifyContent: 'flex-start'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      '.title': {
        variant: 'customVariants.title',
        padding: '2rem',
        backgroundColor: 'black',
        '::after': {} // resetter for after
      }
    }
  }
}
