export default {
  primary: {
    color: 'light',
    border: 'none',
    backgroundColor: 'primary',
    fontSize: '0.85rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1.5rem 3rem',
    borderColor: '#383838',
    transition: 'all ease-in-out 0.5s',
    clipPath: 'polygon(15% 0, 85% 0, 100% 50%, 85% 100%, 15% 100%, 0% 50%)',
    ':hover': {
      backgroundColor: 'dark',
      color: 'primary'
    }
  },
  secondary: {
    color: 'light',
    border: 'none',
    backgroundColor: 'primary',
    fontSize: '0.85rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1.5rem 3rem',
    borderColor: '#383838',
    clipPath: 'polygon(15% 0, 85% 0, 100% 50%, 85% 100%, 15% 100%, 0% 50%)',
    ':hover': {
      backgroundColor: 'dark',
      color: 'primary'
    }
  }
}
